import React, {useState} from 'react'
import { HoreContainer, HeroBg, ImgBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements';
import  LinkForm  from '../linkForm';

const HeroSection = () => {
    const [hover, setHover] = useState(false);
    const onHover = () => {
        setHover(!hover);
    };
    const url = 'https://demos.creative-tim.com/now-ui-kit-react/static/media/header.ffcc99c4.jpg';
    return (
        <HoreContainer>
            <HeroBg>
                {/* <ImgBg src={url} /> */}
            </HeroBg>
            <HeroContent>
                <HeroH1>dShort</HeroH1>
                <HeroP>
                    Enter url which you what to short
                </HeroP>
                <HeroBtnWrapper>
                    <LinkForm style="display: flex"></LinkForm>
                </HeroBtnWrapper>
            </HeroContent>
        </HoreContainer>
    )
}

export default HeroSection
