import React, { Component } from "react";
import { fireDb } from "../../utilities/firebase";
import { v4 as uuidv4 } from "uuid";
import "./linkForm.css";
import QRCode from 'qrcode';
import { HashLoader
} from 'react-spinners';
import { css } from "@emotion/react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';
import BMCIcon from '../../images/bmc-button.svg';
class LinkForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      shortLink: '',
      displayShortLinkSection: false,
      qrCodeUrl: '',
      loading: false,
      error1: false,
      error2: false
    }
  }
  override = css`
    display: block;
    margin: auto;
    margin-top: 5%;
`;
  url = '';
  mountedStyle = {
    animation: "inAnimation 0.7s ease-in"
  };
  unmountedStyle = {
    animation: "outAnimation 0.7s ease-out",
    animationFillMode: "forwards"
  };
  setUrl(value) {
    this.url = value;
    this.setState({ displayShortLinkSection: false, error1: false, error2: false });
  }

  handleFormSubmit = async (e) => {
    e.preventDefault();
    this.setState({ displayShortLinkSection: false, error1: false, error2: false });
    if(!this.url) {
      this.setState({ error1: true });
    }
    else if(!validator.isURL(this.url)){
      this.setState({ error1: false, error2: true });
    }
    else {
      this.setState({ error1: false, error2: false });
      this.setState({ shortLink: '', displayShortLinkSection: false, qrCodeUrl: '', loading: true });
      let code = uuidv4();
      await fireDb.collection("urls").add({
        url: this.url,
        code: code,
        date: new Date().toString()
      });
      document.getElementById("short-form").reset();
      let shortLink = 'https://www.dshort.in/l/' + code;
      this.setState({ shortLink: shortLink, displayShortLinkSection: true, loading: false });
      this.generateQRCode(shortLink);
      this.storeInLocalStorage(shortLink);
      this.url = '';
    }
  };

  generateQRCode = async (shortLink) => {
    try {
      const response = await QRCode.toDataURL(shortLink);
      this.setState({ shortLink: shortLink, displayShortLinkSection: true, qrCodeUrl: response });
    }
    catch (error) {
      console.log(error);
    }
  }

  storeInLocalStorage = (shortLink) => {
    var dShort_link_Arr = [];
    var dShort_link_Obj_From_LS = localStorage.getItem('dShort_link_Obj');
    var today = new Date();
    var date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
    var mapkey = {
      key: shortLink,
      value: this.url,
      date: date
    };
    if(dShort_link_Obj_From_LS) {
      dShort_link_Arr = JSON.parse(dShort_link_Obj_From_LS);
      if(dShort_link_Arr.length == 20) {
        dShort_link_Arr = dShort_link_Arr.slice(1,20);
      }
    }
    dShort_link_Arr.push(mapkey);
    localStorage.setItem('dShort_link_Obj', JSON.stringify(dShort_link_Arr));
  }

  clearForm = (event) => {
    event.preventDefault();
    this.shortLink = 'Hero';
    this.setState({ shortLink: '', displayShortLinkSection: false })
    document.getElementById("short-form").reset();
    this.setState({ displayShortLinkSection: false, error1: false, error2: false });
  }

  copyToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = this.state.shortLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toast.success('🦄 Copied To Clipboard!', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
    });
  };

  render() {
    toast.configure();
    return (
      <>
        <div className="form-container">
        {
          this.state.error1 ?
          <div className="short_error">Please enter Url</div> : <span></span>
        }
        {
          this.state.error2 ?
          <div className="short_error">Please enter valid Url</div> : <span></span>
        }
          <form id="short-form">

            <div className="home__inputContainer">
              <input className="short-input"
                type="text"
                onChange={(e) => this.setUrl(e.target.value)}
                placeholder="Enter the URL here..." required
              />
              <div className="button-wrap">
                <div>
                  <button className="short-submit" onClick={this.clearForm}>Clear</button>
                  <button type="submit" className="short-submit" onClick={this.handleFormSubmit}>Short It</button>
                </div>
              </div>
            </div>
          </form>
          {
            this.state.loading ?
              <HashLoader
              css={this.override} color={'#01bf71'} size={40} />
              :
              <div className="result-container" style={this.state.displayShortLinkSection ? this.mountedStyle : this.unmountedStyle}>
                {
                  this.state.displayShortLinkSection &&
                  <div>
                    <section className="result-section">

                      <div className="shortlink__wrap">
                        <div className="shortlink-input">
                          <textarea className="shortlink__wrap__input textarea_input" type="text" value={this.state.shortLink}></textarea>
                        </div>
                        <div className="shortlink-button">
                          <input className="shortlink__wrap__input button__input" type="button" value="Copy" onClick={this.copyToClipboard} />
                        </div>
                      </div>
                    </section>
                    <div className="shortlink_qr_code_wrap">
                      <div className="shortlink_qr_code">
                        <img className="shortlink_qr_code_img" src={this.state.qrCodeUrl} />
                      </div>
                    </div>
                    <div className="support_us_container">
                      <div className="support_us_wrapper">
                        <a className="support_us_link" href="https://www.buymeacoffee.com/dockdevelopers" target="_blank"><img className="support_us_img" src={BMCIcon} /></a>
                      </div>
                    </div>
                  </div>
                }

              </div>
          }
        </div>
      </>
    );
  }
}

export default LinkForm