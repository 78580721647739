import React from 'react';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, SubTitle, BtnWrap, FormWrap, FormContent, FormTextArea, FormP, Form, FormButton, FormInput, FormLabel, FormH1, SupportUsContainer, SupportUsWrapper, SuportUsLink, SupportUsImg } from './InfoElements';
import { fire } from '../../utilities/firebase';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BMCIcon from '../../images/bmc-button.svg';

const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, img, alt, primary, dark, dark2}) => {

    var state = {
        customer: {
          name : '',
          email : '',
          message : '',
          date: new Date().toString()
        }
      }

    const handleNameChanged = event => {
        var customer        = state.customer;
        customer.name  = event.target.value;
        state.customer = customer;
      }
    
    const  handleEmailChanged = event => {
        var customer      = state.customer;
        customer.email = event.target.value;
        state.customer = customer;
      }
    
    const  handleMessageChanged = event => {
        var customer    = state.customer;
        customer.message = event.target.value;
        state.customer = customer;
      }

    const handleSubmit = event => {
        toast.configure();
        event.preventDefault();
        var formData = state.customer;
        fire.database().ref('contact').orderByKey().limitToLast(100);
        fire.database().ref('contact').push(formData);
        document.getElementById("contact-form").reset();
        toast.success('🦄 Thanks for the message. We will get back to you soon!', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        });
    };
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <SubTitle darkText={darkText}>{description}</SubTitle>
                                <BtnWrap>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <FormWrap>
                                <FormContent>
                                    <Form onSubmit={handleSubmit} id="contact-form">
                                        <FormH1>Contact Us</FormH1>
                                        <FormP>Please contact us if you have any suggestion or query.</FormP>
                                        <FormLabel htmlFor='name'>Name</FormLabel>
                                        <FormInput name="name" type='text' onChange={handleNameChanged.bind()} required />
                                        <FormLabel htmlFor='email'>Email</FormLabel>
                                        <FormInput name="email" type='email' onChange={handleEmailChanged.bind()} required />
                                        <FormLabel htmlFor='message'>Message</FormLabel>
                                        <FormTextArea name="message" type='text' onChange={handleMessageChanged.bind()} required />
                                        <FormButton type='submit'>Submit</FormButton>
                                    </Form>
                                </FormContent>
                            </FormWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
                <SupportUsContainer>
                    <SupportUsWrapper>
                    <SuportUsLink href="https://www.buymeacoffee.com/dockdevelopers" target="_blank"><SupportUsImg src={BMCIcon} /></SuportUsLink>
                    </SupportUsWrapper>               
                </SupportUsContainer>
        </>
    )
}

export default InfoSection