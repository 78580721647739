export const aboutObj = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Free of cost',
    headline: 'All the apps & sites are free to use.',
    description: 'Get access to our exclusive apps & sites without getting charged any fees.',
    buttonLabel: 'Explore',
    imgStart: false,
    //img: require('../../images/about.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}