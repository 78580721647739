import styled from 'styled-components';

export const InfoContainer = styled.div`
    color: #fff;
    //background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#010606')};
    background: linear-gradient( 
        180deg
        , rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100% ), linear-gradient(
        180deg
        ,rgba(0,0,0,0.2) 0%,transparent 100%);
    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 860px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        height: 1000px;
    }
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2
    col1'` : `'col1 col2'` )};

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`;

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`;

export const TopLine = styled.p`
    color: var(--app-primary-color);
    font-size: 16px;
    line-height: 16px;
    font-weight: 700px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const SubTitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const FormWrap = styled.div`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 540px;
        padding-top: 0;
        padding-bottom: 60px;

        @media screen and (max-width: 400px) {
            height: 80%;
        }
`;

export const FormContent = styled.div`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: 480px) {
            padding: 10px;
        }
`;

export const Form = styled.form`
        max-width: 400px;
        height: auto;
        width: 100%;
        z-index: 1;
        display: grid;
        margin: 0 auto;
        padding: 80px 32px;
        border-radius: 4px;
        //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
        margin-top: 2px;

        @media screen and (max-width: 400px) {
            padding: 32px 32px;
        }
`;

export const FormH1 = styled.h1`
        margin-bottom: 20px;
        color: var(--app-primary-color);
        font-size: 48px;
        font-weight: 600;
`;

export const FormP = styled.p`
        color: white;
        margin-bottom: 35px;
        font-size: 18px;
        line-height: 24px;
`;

export const FormLabel = styled.label`
        margin-bottom: 8px;
        font-size: 14px;
        color: #fff;
`;

export const FormInput = styled.input`
        padding: 16px 16px;
        margin-bottom: 32px;
        border: none;
        border-radius: 4px;
`;

export const FormTextArea = styled.textarea`
        padding: 16px 16px;
        margin-bottom: 32px;
        border: none;
        border-radius: 4px;
        height: 100px;
`;

export const FormButton = styled.button`
        background: var(--app-primary-color);
        padding: 16px 0;
        border: none;
        border-radius: 4px;
        color: var(--app-secondary-color);;
        font-size: 20px;
        cursor: pointer;

        &:hover {
            transition: all 0.2s ease-in-out;
            background-color: bisque;
        }
`;

export const SupportUsContainer = styled.div`
    text-align: center;
    display: flex;
    background: rgba(0,0,0,0.6);
    position: relative;
}
`;

export const SupportUsWrapper = styled.div`
    margin: auto;
`;
export const SuportUsLink = styled.a`
    text-decoration: none;
`;

export const SupportUsImg = styled.img`
    width: 50%;
    border-radius: 22px;
`;