import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { aboutObj } from '../utilities/Data';
import Footer from '../components/Footer';
import './style.css';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="dShort-home">
            <Navbar />
            <HeroSection />
            <InfoSection {...aboutObj} />
            <Footer />
        </div>
    )
};

export default Home
