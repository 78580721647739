import React, {useState, useEffect} from 'react';
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink} from './NavbarElements';
import {animateScroll as scroll} from 'react-scroll';
import MyUrl from '../MyUrl';

const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false);
    const [myUrlDialog, openMyUrlDialog] = useState(false);
    var dialogContent = 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste a ipsam repellendus commodi ad, fugit id magnam inventore laudantium autem delectus praesentium incidunt debitis, numquam dicta eveniet obcaecati, itaque quidem?';
    const changeNav = () => {
        if(window.scrollY >= 20) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    const openDialog = () => {
        openMyUrlDialog(true)
    }

    return (
        <>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo  onClick={toggleHome}>dShort</NavLogo>
                    <NavBtn>
                        <NavBtnLink to="myurl" onClick={openDialog}
                            smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >MyUrls</NavBtnLink>
                    </NavBtn>
                </NavbarContainer>
            </Nav>
            
            <MyUrl isOpen={myUrlDialog} onClose={(e) => openMyUrlDialog(false)}>
          {dialogContent}
        </MyUrl>
        </>
    );
}

export default Navbar; 