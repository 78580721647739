import React, { Component } from 'react';
import './myUrl.css';
import { FaShare, FaHeart } from 'react-icons/fa';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BMCIcon from '../../images/bmc-button.svg';

let dialogStyles = {
    // width: '95%',
    // maxWidth: '100%',
    margin: '0 auto',
    position: 'fixed',
    left: '50%',
    top: '11%',
    transform: 'translate(-50%, 0)',
    zIndex: '999',
    backgroundColor: 'antiquewhite',
    padding: '0 20px 30px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    height: '83%',
    overflow: 'scroll',
    overflowX: 'hidden'
};

let dialogCloseButtonStyles = {
    marginBottom: '15px',
    padding: '3px 8px',
    cursor: 'pointer',
    borderRadius: '50%',
    border: 'none',
    width: '30px',
    height: '30px',
    fontWeight: 'bold',
    alignSelf: 'flex-end',
    backgroundColor: 'white',
    color: '#101522'
};

let dialogContentStyles = {
    borderRadius: '12px',
    backgroundColor: '#fff',
    padding: '10px 10px',
    marginBottom: '10px'
};

class MyUrl extends Component {

  copyToClipboard = (link) => {
    const el = document.createElement('textarea');
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
      document.body.removeChild(el);
      toast.success('🦄 Copied To Clipboard!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
      });
  };

    render() {
        toast.configure();
        var dShort_link_Obj_From_LS = localStorage.getItem('dShort_link_Obj');
        var dShort_link_Obj_Arr = [];
        let no_link_present_obj = {
            key: 'No Urls',
            value: 'You haven\'t shorted any Urls'
        }
        if(dShort_link_Obj_From_LS) {
            dShort_link_Obj_Arr = JSON.parse(dShort_link_Obj_From_LS);
        }
        else {
            //dShort_link_Obj_Arr.push(no_link_present_obj);
        }
        var dialog = (
            <div style={dialogStyles} className="myurl_header_container" >
                <div className="myurl_header_wrap">
                    <div className="myurl_header_title">
                        <span>Your last 20 Short URLs</span>
                    </div>
                    <div className="myurl_header_close_btn">
                        <button style={dialogCloseButtonStyles} onClick={this.props.onClose}>x</button>
                    </div>
                </div>
                
                <div>
                    {
                        dShort_link_Obj_Arr.reverse().map(item => <div style={dialogContentStyles}>
                            <div className="dialog_item">
                                <div className="dialog_item_sidebar"></div>
                                <div className="dialog_item_link">
                                    <span className="dialog_item_link_key">{item.key}</span>
                                    <span className="dialog_item_link_value">{item.value}</span>

                                    <div className="dialog_item_meta_data">
                                        <div className="dialog_item_meta_data_time">Created on {item.date}</div>
                                        <div className="dialog_item_meta_data_btn">
                                            <span  className="dialog_item_meta_data_btn_open">
                                                <a  className="dialog_item_meta_data_btn_open_link" href={item.key} target="_blank"><FaShare /></a>
                                            </span>
                                            <span className="dialog_item_meta_data_btn_copy">
                                                <button className="dialog_item_meta_data_btn_copy_btn" onClick={() => this.copyToClipboard(item.key)}>Copy</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }
                </div>
                <div className="dd_credit"><span><span>With <FaHeart className="heart" /> from </span><span><a href="https://dockdevelopers.com" target="_blank">DockDevelopers</a></span></span></div>

                <div className="support_us_container">
                    <div className="support_us_wrapper">
                        <a className="support_us_link" href="https://www.buymeacoffee.com/dockdevelopers" target="_blank"><img className="support_us_img" src={BMCIcon} /></a>
                    </div>
                </div>
            </div>
        );

        var dialog2 = (
            <div style={dialogStyles} className="myurl_header_container myurl_header_container2" >
                <div className="myurl_header_wrap">
                    <div className="myurl_header_title">
                        <span>Your last 20 Short URLs</span>
                    </div>
                    <div className="myurl_header_close_btn">
                        <button style={dialogCloseButtonStyles} onClick={this.props.onClose}>x</button>
                    </div>
                </div>
                <div style={dialogContentStyles}>
                    <div className="dialog_item">
                        <div className="dialog_item_sidebar"></div>
                        <div className="dialog_item_link">
                            <span className="dialog_item_link_key">You have not created any Url yet.</span>
                        <span className="dialog_item_link_value"><a href="#" onClick={this.props.onClose}>Click</a> to create your first short Url. Give it a try, you will love it.</span>
                        </div>
                    </div>
                </div>
                <div className="dd_credit"><span><span>With <FaHeart className="heart" /> from </span><span><a href="https://dockdevelopers.com" target="_blank">DockDevelopers</a></span></span></div>

                <div className="support_us_container">
                    <div className="support_us_wrapper">
                        <a className="support_us_link" href="https://www.buymeacoffee.com/dockdevelopers" target="_blank"><img className="support_us_img" src={BMCIcon} /></a>
                    </div>
                </div>
            </div>
        );

        if (! this.props.isOpen) {
            dialog = null;
            dialog2 = null;
        }
        return (
            <div>
            <div>
                {
                    dShort_link_Obj_Arr.length > 0 ?
                dialog : dialog2
    }
            </div>
            </div>
        );
    }
}

export default MyUrl;