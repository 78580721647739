import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink, SocialMedia, SocialMediaWrap, SocialIcons, SocialIconLink, SocialLogo, WebsiteRights } from './FooterElements';
import logo from '../../images/dd-logo.png';
const Footer = () => {
    

    return (
        <FooterContainer>
            <FooterWrap>
                {/* <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>About Us</FooterLinkTitle>
                            <FooterLink to="/subscribe">Subscribe</FooterLink>
                            <FooterLink to="/subscribe">Testimoniaks</FooterLink>
                            <FooterLink to="/subscribe">Careers</FooterLink>
                            <FooterLink to="/subscribe">Investors</FooterLink>
                            <FooterLink to="/subscribe">Terms of Services</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Social Media</FooterLinkTitle>
                            <FooterLink to="/subscribe">Instagram</FooterLink>
                            <FooterLink to="/subscribe">Facebook</FooterLink>
                            <FooterLink to="/subscribe">Twitter</FooterLink>
                            <FooterLink to="/subscribe">LinkedIn</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer> */}
                    <div><a href="http://www.dockdevelopers.com" target="_blank"><img src={logo} /></a></div>
                <SocialMedia>
                        <WebsiteRights>dShort &copy; {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                    <SocialMediaWrap>
                        {/* <SocialLogo to='/'><img src={logo} /></SocialLogo> */}
                        <SocialIcons>
                            <SocialIconLink href="#" target="_blank" aria-label="Facebook">
                                <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink href="#" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href="#" target="_blank" aria-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>
                            <SocialIconLink href="#" target="_blank" aria-label="LinkedIn">
                                <FaLinkedin />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer