import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';

export const Nav = styled.nav`
   //background: #101522;
    background: ${({scrollNav}) => (scrollNav ? '#101522' : 'transparent')};
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.1s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    //padding: 0 24px;
    max-width: 1100px;
`;

export const NavLogo = styled(LinkRouter)`
    justify-self: flex-start;
    curson: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;
    color: var(--app-primary-color);
`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: tranlate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        margin-top: 25px;
        margin-right: 6%;
        color: var(--app-primary-color);
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavItem = styled.li`
    height: 80px;
`;

export const NavLinks = styled(LinkScroll)`
    color: var(--app-secondary-color);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 0.5rem;
    height: 100%;
    cursor: pointer;

    &.active {
        border-bottom: 3px solid var(--app-primary-color);
    }
`;

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    margin-right: 2%;
`;

export const NavBtnLink = styled(LinkScroll)`
    border-radius: 50px;
    background: var(--app-primary-color);
    white-space: nowrap;
    padding: 10px 22px;
    color: var(--app-secondary-color);
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background-color: bisque;;
    }
`;
