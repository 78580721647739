import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyBDXsEvtNpcygojxWFgGOYGenTfiHnNegI',
  authDomain: 'dshort-dock.firebaseapp.com',
  projectId: 'dshort-dock',
  storageBucket: 'dshort-dock.appspot.com',
  messagingSenderId: '1072826436263',
  appId: '1:1072826436263:web:6763276da51c6c2e322982'
};

export const fire = firebase.initializeApp(firebaseConfig);
export const fireDb = fire.firestore();
