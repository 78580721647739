import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/home';
import ShortLink from './pages/shortLink';
function App() {

  return (
    <div className='app'>
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/l/:code' component={ShortLink} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
