import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { fireDb } from "../utilities/firebase";

function ShortLink() {
  const { code } = useParams();
  const history = useHistory();
  const [url, setUrl] = useState("");

  useEffect(() => {
    let query = fireDb.collection("urls").where("code", "==", code);
    query.onSnapshot((data) => {
      if (data.empty) {
        return history.push("/");
      }
      var finalData = data.docs[0].data();
      var url = finalData.url;
      var httpPrefix = 'http';
      if (url.substr(0, httpPrefix.length) !== httpPrefix) {
        url = httpPrefix + '://' + url;
      }
      window.location = url;
    });
  }, []);

  return <div></div>;
}

export default ShortLink;
